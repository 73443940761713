
.page {
    &-title {
        margin-bottom: 3rem;
    }

    &-content {
        margin-bottom: 3rem;
    }

    &-events {
        margin-bottom: 3rem;
    }

    &-event-month {
        margin-bottom: 1rem;
    }

    &-events-list {
        padding: 0;
        margin: 0 0 3rem 0;

        list-style: none;
    }
}