.navbar {
    background-color: rgba(255, 255, 255, .8);

    &-nav {
        margin: 0;
        padding: 0;

        display: flex;
        flex-direction: row;

        list-style: none;
    }

    &--absolute {
        z-index: 99;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .nav {
        &-item-active {
            .nav-link {
                &:after {
                    position: absolute;
                    left: 30px;
                    bottom: 28px;
        
                    content: "";
        
                    display: block;
        
                    width: calc(100% - 60px);
                    height: 1px;
        
                    background-color: $black;
                }
            }
        }
    }
}

.nav {
    &-brand {
        display: flex;
        align-items: center;

        color: $black;
        font-weight: bold;

        text-decoration: none;
        
        transition: opacity .4s ease-in-out;

        img {
            margin-right: 10px;
        }

        &:hover {
            text-decoration: none;
            color: $black;

            opacity: 0.8;
        }
    }

    &-item-active {
        .nav-link {
            color: black !important;
        }
    }

    &-link {
        position: relative;

        display: block;
        padding: 30px;

        font-size: 1rem;
        line-height: 1rem;
        color: $black;

        text-decoration: none;

        &:after {
            position: absolute;
            left: 30px;
            bottom: 28px;

            content: "";

            display: block;

            width: 0px;
            height: 1px;

            background-color: $black;
        
            transition: width 0.4s ease-in-out;
        }

        &:hover {
            color: $black;

            text-decoration: none;

            &:after {
                width: calc(100% - 60px);
            }
        }
    }
}