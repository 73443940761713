.header {
    position: relative;

    height: 50vh;
    margin-bottom: 80px;

    background-image:
        url('../images/mountains.png'),
        linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .5)),
        url('../images/header.jpg');
    background-position: center bottom -2px, center bottom, center;
    background-size: 100% auto, cover, cover;
    background-repeat: no-repeat;

    &-jumbo {
        position: absolute;
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);

        text-align: center;

        color: white;
        text-shadow: 0px 0px 50px #666;
    }

    &-title {
        font-size: 3.5rem;
    }

    &-subtitle {
        font-size: 2.5rem;
    }
}