$enable-gradients: false;
$enable-shadows: false;

$font-family-sans-serif: 'Lato', sans-serif;
$headings-font-family: 'Merriweather Sans', sans-serif;

$reference-base: 16;
$font-size-base: 1rem; // 16px;
$h1-font-size: $font-size-base * 3.125;
$h2-font-size: 24 / $reference-base + "rem";
$h3-font-size: 20 / $reference-base + "rem";
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.1;
$headings-font-weight: 700;

$small-font-size: $font-size-base * 0.9375;

@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';

@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/utilities/flex';
@import '../../node_modules/bootstrap/scss/utilities/display';
@import '../../node_modules/bootstrap/scss/tables';

a {
    color: black;
    text-decoration: underline;

    &:hover {
        color: black;
        text-decoration: none;
    }
}
