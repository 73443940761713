.box {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 60px;

    border-bottom: 1px solid #eee;
    padding-bottom: 20px;

    &:last-of-type {
        border-bottom: none;
    }

    &-alert {
        margin-bottom: 100px;
        
        &:before,
        &:after {
            padding: 0 30px;

            content: "!";
            display: block;

            font-size: 4rem;
            color: #ffa6a6;
            font-family: $headings-font-family;
        }
    }

    &-vyprava {
        p {
            margin-bottom: .5rem;
        }
    }
}